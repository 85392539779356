.font-dancing-script {
  font-family: Dancing Script;
  font-size: 55px;
  font-weight: 700;
}

@media (max-width: 992px) {
  .font-dancing-script {
    font-size: 45px;
  }
}

body {
  padding-top: 80px;
}

.green-text {
  color: rgb(21, 180, 190) !important;
}

.navigation-font {
  font-size: 18px !important;
}

.green-bg {
  background-color: rgb(21, 180, 190);
}

.text-decoration-none {
  text-decoration: none;
}

.logo {
  width: 200px;
  height: auto;
}

.btn-menu-mobile {
  border: none !important;
}

.background-image-section-1 {
  background-image: url("images/background/bg-home.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 600px;
}

.background-service {
  background-image: url("images/background/bg-service.webp");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.form-home-about-us-inner-up {
  border-radius: 20px 20px 0px 0px;
}

.form-home-about-us-inner-down {
  border-radius: 20px 20px 0px 0px;
}

.link-style-button {
  color: white;
  background-color: rgb(21, 180, 190);
  padding: 10px 70px;
  border-radius: 10px;
}

.white-botom-border {
  border-bottom: 4px solid white;
  width: 300px;
  padding-bottom: 40px;
}

.white-botom-border-baner {
  border-bottom: 6px solid white;
  width: 300px;
  padding-bottom: 40px;
}

.green-botom-border {
  border-bottom: 4px solid rgb(21, 180, 190);
  width: 300px;
  padding-bottom: 40px;
}

.service-container {
  padding-left: 250px;
  padding-right: 250px;
}

.single-service-container {
  border-radius: 20px;
  border: 2px solid white;
  padding: 20px;
}

.icon-service {
  width: 100px;
  height: 100px;
}

.background-about-us {
  background-image: url("images/background/bg-about-us.webp");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.about-us-image {
  max-width: 100%;
  height: auto;
  border-radius: 20px 20px 0px 0px;
}

.icon-contact {
  color: rgb(21, 180, 190);
  font-size: 50px;
}

.form-input-fields {
  margin-bottom: 40px;
  border: none;
  border-bottom: 1px solid rgb(21, 180, 190);
  width: -webkit-fill-available;
}

.btn-form-submit {
  border: none;
  background-color: transparent;
  color: rgb(21, 180, 190);
}

.baner{
  background-position: center;
  height: 500px;
}

@media (max-width: 991px) {
  .baner{
    background-position: center;
    height: 350px;
  }
}

.baner-about-us {
  background-image: url("images/background/about-us-baner.webp");
}

.baner-service {
  background-image: url("images/background/baner-service.webp");
}

.baner-about-me {
  background-image: url("images/background/bg-home.webp");
}

.baner-price {
  background-image: url("images/background/baner-service.webp");
}

@media (max-width: 991px) {
  .baner-about-us {
    height: 350px;
  }
}

.border-radius-20 {
  border-radius: 20px;
}

.border-left-green {
  border-left: solid 5px rgb(21, 180, 190);
}

.counter {
  font-size: 70px;
  color: white;
  font-weight: 700;
}

.counter-image {
  width: 100px;
  height: auto;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
}

.gallery-image {
  width: 100%;
  margin: 4%;
}

button {
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 24px !important;
  font-weight: 700;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

@media (min-width: 992px) {
  .border-service {
    border-top: 4px solid rgb(21, 180, 190);
    border-bottom: 4px solid rgb(21, 180, 190);
    border-radius: 20px;
  }
}

@media (max-width: 991px) {
  .border-service {
    border-left: 4px solid rgb(21, 180, 190);
    border-right: 4px solid rgb(21, 180, 190);
    border-radius: 20px;
  }
}

@media (min-width: 992px) {
  .border-service-white {
    border-top: 4px solid white;
    border-bottom: 4px solid white;
    border-radius: 20px;
  }
}

@media (max-width: 991px) {
  .border-service-white {
    border-left: 4px solid white;
    border-right: 4px solid white;
    border-radius: 20px;
  }
}

.h-300 {
  min-height: 300px;
}

.bb {
  border-bottom: 6px solid rgb(21, 180, 190);
}

.bb-1 {
  border-bottom: 1px solid rgb(21, 180, 190);
}

.bb-dotted {
  border-bottom: 1px dotted grey;
}

.bb-green {
  border-bottom: 5px solid rgb(21, 180, 190);
}

.customers-bg {
  background-color: rgb(245, 245, 245);
}

.customers-mr {
  margin-right: -50px;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath stroke="rgba(21, 180, 190, 1)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/%3E%3C/svg%3E');
}

.header-color {
  background-color: white;
}

.map-container {
  max-height: 600px;
}

.about-me-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.about-me-wrapper section {
  flex: 1;
}




/*PhotoGallery */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.lightbox-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.lightbox-image {
  display: block;
  width: 100%;
  height: auto;
}

.lightbox-close, .lightbox-prev, .lightbox-next {
  position: absolute;
  background: rgba(21, 180, 190, 0.8);
  border: none;
  padding: 10px;
  cursor: pointer;
  color: white;
  width: 50px;
  border-radius: 10px;
}

.lightbox-close {
  top: 10px;
  right: 10px;
  z-index: 1001;
}

.lightbox-prev {
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1001;
}

.lightbox-next {
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1001;
}








@keyframes fadeIn {
  from {
    opacity: 0; /* Početna neprovidnost */
  }
  to {
    opacity: 1; /* Krajnja providnost */
  }
}

/* Primena animacije na element */
.fade-in-element {
  opacity: 0; /* Postavite početnu vrednost neprovidnosti */
  animation: fadeIn 1s ease-in-out forwards; /* Definisanje animacije */
  /* font-size: 24px;  */
  /* color: #333; */
  /* margin: 50px;  */
}